html, body, #root {
  box-sizing: border-box;
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  color: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}